import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import {
  BehaviorSubject,
  Observable,
  firstValueFrom,
} from 'rxjs';

import {
  LoginResponse,
  LoginUser,
  Plan,
  WebsiteAnalyze,
} from '../../../shared/models/user.models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter, map, tap } from 'rxjs/operators';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  storageKey = ConstantService.localStorageKeys.access_Token;
  userDataKey = ConstantService.localStorageKeys.userId;
  logout_success: string;
  logout_msg: string;
  loginData1: any;
  nextUrl: string;
  googleLoginOptions = {
    scope: 'profile email',
  };

  private userLoggedSubject: BehaviorSubject<Plan> = new BehaviorSubject<Plan>(
    undefined
  );
  private userLoggedPlanSubject: BehaviorSubject<Plan> =
    new BehaviorSubject<Plan>(undefined);
  private userLoggedNbWebsitesSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(undefined);
  private updateUserLoggedNbWebsitesSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(undefined);
  private userLoggedWebSitesAnalyseSubject: BehaviorSubject<
    Array<WebsiteAnalyze>
  > = new BehaviorSubject<Array<WebsiteAnalyze>>(undefined);

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private websiteAnalyzeService: WebsiteAnalyzeService,
  ) {}


  /**
   * This function to remove token from local stroage
   */
  removeToken() {
    // localStorage.removeItem(this.storageKey);
    // localStorage.removeItem(this.userDataKey);
  }

  /**
   * This function is used to get token key that user get it when he log in
   */
  getToken() {
    return localStorage.getItem(this.storageKey);
  }

  /**
   * This function to set token
   */
  setToken(token: string) {
    localStorage.setItem(this.storageKey, token);
  }

  /**
   * authenticated USER
   */
  get userLoggedObs(): Observable<Plan> {
    return this.userLoggedSubject.asObservable();
  }
  set userLogged(userLogged: Plan) {
    // this.userLoggedPlan = <Plan>{

    //   websites: userLogged['websites'] || 1,
    //   locationSelectionAllowed: userLogged['xxxx'] || false,
    //   nbInternalPage: userLogged['nbInternalPage'] || 1,
    //   nbCompetitors: userLogged['xxxx'] || 1,
    //   type: userLogged['xxxx'] || 'free'
    //  // monthlyRate: userLogged['monthlyRate'] || 0,
    // };
    // console.log(this.userLoggedPlan);

    this.userLoggedSubject.next(userLogged);
  }
  /**
   * authenticated USER PLAN
   */
  get userLoggedPlanObs(): Observable<Plan> {
    return this.userLoggedPlanSubject
      .asObservable()
      .pipe(filter((data) => data !== undefined));
  }
  set userLoggedPlan(plan: Plan) {
    this.userLoggedPlanSubject.next(plan);
  }
  /**
   * authenticated USER STATS
   */
  get userLoggedNbWebsitesObs(): Observable<number> {
    return this.userLoggedNbWebsitesSubject
      .asObservable()
      .pipe(filter((data) => data !== undefined));
  }
  set userLoggedNbWebsites(nb: number) {
    this.userLoggedNbWebsitesSubject.next(nb);
  }
  get userLoggedWebSitesAnalyseObs(): Observable<Array<WebsiteAnalyze>> {
    return this.userLoggedWebSitesAnalyseSubject
      .asObservable()
      .pipe(filter((data) => data !== undefined));
  }
  set userLoggedWebSitesAnalyse(wsa: Array<WebsiteAnalyze>) {
    this.userLoggedWebSitesAnalyseSubject.next(wsa);
  }
  get updateUserLoggedNbWebsitesObs(): Observable<boolean> {
    return this.updateUserLoggedNbWebsitesSubject.asObservable();
  }
  updateUserLoggedNbWebsites() {
    this.updateUserLoggedNbWebsitesSubject.next(true);
  }

  get isAuthenticated(): Observable<boolean> {
    if (this.router.url.slice(0, 5) !== '/demo')
      return this.userLoggedSubject.asObservable().pipe(
        filter((user) => user !== undefined),
        map((user) => !!user)
      );
  }
  registerUser(userData): Observable<any> {
    return this.httpClient.post<any>(ConstantService.apiRoute.signup, userData);
  }

  loginUser(loginData: LoginUser): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(ConstantService.apiRoute.login, loginData).pipe(
      tap((response: LoginResponse) => {
        localStorage.setItem(this.storageKey, response.accessToken);
        localStorage.setItem(this.userDataKey, response.userId);
      })
    );
  }
  getCurrentUser(userId: string): Observable<any> {
    const url = `${ConstantService.apiRoute.getUserDetail}/${userId}`;
    return this.httpClient.get<any>(url);
  }
  async checkSharedWithUser(userEmail: string):Promise<boolean> {
    const website = await firstValueFrom(this.websiteAnalyzeService.isSharedWith(userEmail))
      if (website.length > 0) {
      return true
      }else{
        return false 
      }

  }


  handlePostLoginRedirect(userId): void {

    this.getCurrentUser(userId).subscribe(async (response: any) => {

      const User = response.user;
      const isSharedWithUser = await this.checkSharedWithUser(User.email);
      // Paid active users
      if ( User.subStatus === "active" || isSharedWithUser) {
        this.router.navigate(['/home']);
      // New free users or non-renewed paid ones
      } else 
      {
        this.router.navigate(['/buyPlan']);
      }

    });}



  async loginWithGoogle(gsiResponse, gmuId?) {
    localStorage.setItem(ConstantService.localStorageKeys.provider, 'google');
    localStorage.setItem(ConstantService.loadingUrl.device, 'mobile');
    const accessToken = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token
    );
    const headers = accessToken
      ? new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        })
      : {};
    try {
      const response: any = await this.httpClient
        .post(
          ConstantService.apiRoute.registerGoogleUser +
            `${gmuId ? '?gmuId=' + gmuId : ''}`,
          {
            idToken: gsiResponse.credential,
            provider: 'google',
          },
          { headers }
        )
        .toPromise();

      localStorage.setItem(
        ConstantService.localStorageKeys.access_Token,
        response.accessToken
      );
      localStorage.setItem(
        ConstantService.localStorageKeys.refresh_Token,
        response.refreshToken
      );
      localStorage.setItem(
        ConstantService.localStorageKeys.access_Token_Google,
        gsiResponse.credential
      );
      localStorage.setItem(
        ConstantService.localStorageKeys.userId,
        response.userId
      );

      if (response.planId) {
        window.location.href =
          window.location.origin +
          '/home/buyPlan?gmuId=' +
          gmuId +
          '&planId=' +
          response.planId;
      } else {
        this.handlePostLoginRedirect(response.userId);
      }
    } catch (error) {}
  }

  async logoutUser() {
    if (
      localStorage.getItem(ConstantService.localStorageKeys.provider) !==
      'google'
    ) {
      localStorage.clear();
    } else {
      // try {
      const seenDemo = localStorage.getItem(
        ConstantService.localStorageKeys.seenDemo
      );
      localStorage.clear();
      if (!!seenDemo) {
        localStorage.setItem(
          ConstantService.localStorageKeys.seenDemo,
          seenDemo
        );
      }
      localStorage.clear();
      // } catch (error) {
      //   console.log('the error is', error);
      // }
    }
    this.router.navigate(['/auth/login']);
  }
}

