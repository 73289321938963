
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2 } from '@angular/core'
import { Subject } from 'rxjs'
import { fadeIn, fadeInOut } from '../animations'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FeedbackService } from 'src/app/services/common/feedback.service';
import { ConstantService } from '../../constant/constant.service';
import { UserService } from 'src/app/services/common/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-feedback-widget',
  templateUrl: './feedback-widget.component.html',
  styleUrl: './feedback-widget.component.scss',
  animations: [fadeInOut, fadeIn],
})
export class FeedbackWidgetComponent implements OnInit, AfterViewInit{
  feedbackForm: FormGroup;
  public theme: 'blue' | 'grey' | 'red' = 'blue';
  public _visible = false
  userId: string;
  user: any;
  email: any;
  userName: any;
  public selectedAction: string | null = null;

  constructor(private fb: FormBuilder,
    private feedbackService: FeedbackService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.feedbackForm = this.fb.group({
      feedback: ['',[Validators.required, Validators.minLength(5), this.noHtmlValidator]]
    });
  }

  public get visible() {
    return this._visible
  }

  public set visible(visible) {
    this._visible = visible
  }

  async ngOnInit() {
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
    this.user = await this.userService.getUserDetail(this.userId).toPromise();
    this.email = this.user.email
    this.userName = this.user.name
  }
  ngAfterViewInit() {
    // Initialize or reset the testimonial widget
    if (this.selectedAction === 'improvement') {
      this.loadImprovementWidget();
    }
  }

  public toggleChat() {
    this.visible = !this.visible
    if (!this.visible) {
      this.selectedAction = null; // Reset action on close
    }
  }
  public selectAction(action: string) {
    this.selectedAction = action;
    if (action === 'improvement') {
      setTimeout(() => this.loadImprovementWidget(), 0);
    }
  }
  private loadImprovementWidget() {
    // Check if the container exists
    const container = this.el.nativeElement.querySelector('.testimonial-widget-container');
    if (container) {
      // Remove previous script and div if they exist
      const existingScript = container.querySelector('script[src="https://testimonial.to/js/widget-embed.js"]');
      const existingDiv = container.querySelector('.testimonial-to-embed');
      if (existingScript) {
        this.renderer.removeChild(container, existingScript);
      }
      if (existingDiv) {
        this.renderer.removeChild(container, existingDiv);
      }

      // Create and append new script and div elements
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://testimonial.to/js/widget-embed.js';
      script.async = true;

      script.onload = () => {
        console.log('Testimonial script loaded');
      };
      script.onerror = (error) => {
        console.error('Error loading testimonial script:', error);
      };

      const div = this.renderer.createElement('div');
      this.renderer.setAttribute(div, 'class', 'testimonial-to-embed');
      this.renderer.setAttribute(div, 'data-url', 'https://embed-v2.testimonial.to/c/speetals?theme=light');
      this.renderer.setAttribute(div, 'data-allow', 'camera;microphone');
      this.renderer.setAttribute(div, 'data-resize', 'true');

      this.renderer.appendChild(container, div);
      this.renderer.appendChild(container, script);
    } else {
      console.error('Container for testimonial widget not found');
    }
  }
  onSubmit() {
    if (this.feedbackForm.controls.feedback.valid) {
      const feedbackData = {
        feedBack: this.feedbackForm.value.feedback,
        email: this.email,
        userId: this.userId,
        userName: this.userName
      };
      this.toggleChat();
      this.feedbackService.postFeedback(feedbackData).subscribe(
        response => {
          console.log('Feedback sent successfully:', response);
          this.snackBar.open('Feedback sent successfully !', 'close', {
            duration: 2000,
          });
       
        },
        error => {
          console.error('Error sending feedback:', error);
          this.snackBar.open('Error sending feedback ', 'close', {
            duration: 2000,
          });
         
        }
      );
    } else {
      console.error('Feedback is invalid');
      this.snackBar.open('Feedback is invalid', 'close', {
        duration: 2000,
      });
  
    }
  }

  // Validator to ensure no HTML is present in the feedback
  noHtmlValidator(control: FormControl) {
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;
    if (htmlRegex.test(control.value)) {
      return { 'htmlDetected': true };
    }
    return null;
  }
}
