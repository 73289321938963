const serverUrl = "https://api.speetals.com/app";
const pageSpeedUrl= "https://www.googleapis.com/pagespeedonline/v5/runPagespeed";
const googleClientId = "791751696191-2vqo9s4vdckbgnirjevb0qhgkma4glhb.apps.googleusercontent.com"
const StripeKey = "pk_live_51JoVNiBzqAjja9iCTdHajQb8rA36pcr1p5Bl2meep4uY9CyTLIzH99LbVR3OYQNPaRFXGp70CtsWYl4FmSDCkAcJ0098zQeBuM"
export const environment = {
  serverUrl: `${serverUrl}`,
  apiUrl:`${serverUrl}`,
  apiPageSpeedUrl:`${pageSpeedUrl}`,
  production: true,
  googleClientId,
  stripeKey : `${StripeKey}`,
};
