<div class="wrapper {{theme}}" style="z-index: 100;">
  <div class="chat-box" *ngIf="visible" [@fadeInOut]="visible" style="z-index: 100;">
    <div class="chat-box-header">
      <div class="">
        <h2 class="operator-name m-0">Feedback</h2>
      </div>
    </div>

    <div *ngIf="!selectedAction" class="d-flex justify-content-center h-100">
      <button class="btn btn-light w-100" (click)="selectAction('feedback')"><span class="fs-5">Suggest a feature</span></button>
      <button class="btn btn-light w-100" (click)="selectAction('improvement')"> <span class="fs-5">Give a testimonial</span></button>
    </div>

    <div *ngIf="selectedAction === 'feedback'" class="feedback">
      <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()">
        <div class="chat-box-main">
          <textarea placeholder="Describe here the feature you are requesting..." class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="feedback"></textarea>
        </div>
        <button type="submit" class="d-flex justify-content-center align-items-center btn btn-primary chat-box-footer w-100">
          <span class="mx-2">Suggest feature</span>
          <mat-icon>send</mat-icon>
        </button>
      </form>
    </div>

    <div *ngIf="selectedAction === 'improvement'" class="improvement">
      <!-- Placeholder for the Testimonial Widget -->
      <div class="testimonial-widget-container"></div>
    </div>


  </div>

  <button class="chat-button" (click)="toggleChat()" style="z-index: 100;">
    <span [@fadeIn] *ngIf="visible">✕</span>
    <span [@fadeIn] *ngIf="!visible"><mat-icon>feedback</mat-icon></span>
  </button>
</div>
