import { HttpClient, HttpHeaders } from '@angular/common/http';
import { withCache } from '@ngneat/cashew';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { BarUrl, WebsiteAnalyze } from '../../../shared/models/user.models';

@Injectable({
  providedIn: 'root',
})
export class WebsiteAnalyzeService {
  apiRoutes: any;
  auth_token: string;
  formValue: any;
  constructor(private httpClient: HttpClient) {
    this.auth_token = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token
    );
    this.apiRoutes = ConstantService.apiRoute;
  }
  /**
   * for post data
   * @param data
   * @returns
   */
  postWebsiteAnalyze(data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.post<any>(this.apiRoutes.websiteanalyze, data, {
      headers: headers,
    });
  }

  postGuestUserWebsiteAnalyze(data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.httpClient.post(
      ConstantService.apiRoute.analyzeGuestWeb,
      data,
      { headers }
    );
  }

  saveDataForLoggedInUser(gmuId, userId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.httpClient.post(
      ConstantService.apiRoute.saveLoggedUserData + '/' + gmuId + '/' + userId,
      {},
      { headers }
    );
  }

  /**
   * for put data
   * @param id
   * @param data
   * @returns
   */
  putWebsiteAnalyze(
    id: string,
    data: WebsiteAnalyze
  ): Observable<WebsiteAnalyze> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.put<WebsiteAnalyze>(
      this.apiRoutes.websiteanalyze + '/' + id,
      data,
      { headers: headers }
    );
  }

  /**
   * Invite shared with user
   * @param id
   * @param data
   * @returns
   */
  inviteSharedWithUser(
    id: string,
    data: Array<string>,
    title: string,
    firstName: string,
    lastName: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    console.log(data);
    return this.httpClient.post(
      this.apiRoutes.websiteanalyze +
        '/share-access/' +
        id +
        '/' +
        title +
        '/' +
        lastName +
        '/' +
        firstName,
      data,
      { headers: headers }
    );
  }
  /**
   * for put data
   * @param projectId
   * @param mails
   * @returns
   */
  updateMails(projectId: string, mails: any): Observable<WebsiteAnalyze> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.put<WebsiteAnalyze>(
      this.apiRoutes.websiteanalyze + '/update/' + projectId + '/' + mails,
      mails,
      { headers: headers }
    );
  }
  /**
   *
   * @param userId
   * @returns
   */
  getWebsiteAnalyze(userId, ipo?: boolean): Observable<Array<WebsiteAnalyze>> {
    if (
      localStorage.getItem(ConstantService.localStorageKeys.access_Token) !==
      null
    ) {
      this.auth_token = localStorage.getItem(
        ConstantService.localStorageKeys.access_Token
      );
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth_token}`,
      });
      return this.httpClient.get<Array<WebsiteAnalyze>>(
        this.apiRoutes.websiteanalyze +
          '/all/' +
          userId +
          `${!!ipo ? '?ipo=true' : ''}`,
        { headers: headers }
      );
    } else {
      this.delay(500);
      //this.getWebsiteAnalyze(userId);
    }
  }
  isSharedWith(email): Observable<Array<WebsiteAnalyze>> {
    if (
      localStorage.getItem(ConstantService.localStorageKeys.access_Token) !==
      null
    ) {
      return this.httpClient.get<Array<WebsiteAnalyze>>(
        this.apiRoutes.websiteanalyze + '/SharedWith/' + email
      );
    } else {
      this.delay(500);
      //this.getWebsiteAnalyze(userId);
    }
  }

  getProjectByProjectId(projectId): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.httpClient.get(
      this.apiRoutes.websiteanalyze + '/projectId/' + projectId,
      { headers }
    );
  }

  getWebsiteAnalyzeById(userId, id): Observable<WebsiteAnalyze> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.get<WebsiteAnalyze>(
      this.apiRoutes.websiteanalyze + '/' + userId + '/' + id,
      { headers: headers, context: withCache() }
    );
  }
  getAllWebsiteAnalyze(userId, email?): Observable<WebsiteAnalyze> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.get<WebsiteAnalyze>(
      this.apiRoutes.websiteanalyze +'/SharedWith/' + userId + '/' + email,
      { headers: headers, context: withCache() }
    );
  }

  getFirstPartyDataMetrics(
    userId: string,
    queryInterval: string,
    queryLevel: string,
    pageType: string,
    isShared: boolean,
    projectId?: string,
    url?: string,
    hasPagination?: boolean,
    pageNumber?: number,
    pageSize?: number,
    search?: string,
    location?: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      //Authorization: `Bearer ${this.auth_token}`,
    });
    let queryString = '';

    if (projectId) {
      queryString += `&projectId=${projectId}`;
    }

    if (url) {
      queryString += `&url=${url}`;
    }

    if (search) {
      queryString += `&searchKeyword=${search}`;
    }
    queryString += isShared ? '&sharedWithMe=yes' : '';
    if (hasPagination) {
      queryString += `&hasPagination=true&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.httpClient.get(
      `${ConstantService.apiRoute.firstPartyMetricData}/${userId}/${
        location || 'Global'
      }?queryInterval=${queryInterval}&queryLevel=${queryLevel}&pageType=${pageType}` +
        queryString,
      { headers }
    );
  }

  getAttributionData(
    projectId: string,
    metric: string,
    queryInterval: string,
    location?: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.get(
      `${ConstantService.apiRoute.attributionData}/${projectId}/${metric}/${
        location || 'Global'
      }?queryInterval=${queryInterval}`,
      { headers }
    );
  }
  getFirstPartyVitalByMetric(
    userId: string,
    projectId: string,
    metric: string,
    queryInterval: string,
    queryLevel: string,
    url?: string,
    sharedWithMe = false,
    location?: string,
    worstPerformingPages?: string,
    limit?: string,
    startDate?: string,
    endDate?: string
  ): any {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      //Authorization: `Bearer ${this.auth_token}`,
    });
    const urlQuery = url ? `&url=${url}` : '';
    const shared = sharedWithMe ? '&sharedWithMe=yes' : '';
    const topPages = worstPerformingPages ? '&worstPerformingPages=yes' : '';
    const limitQ = limit && limit !== 'custom' ? '&limit=' + limit : '';
    const startDateQ = startDate ? '&startDate=' + startDate : '';
    const endDateQ = endDate ? '&endDate=' + endDate : '';

    return this.httpClient.get(
      `${
        ConstantService.apiRoute.firstPartyMetricData
      }/${userId}/${projectId}/${metric}/${
        location || 'Global'
      }?queryInterval=${queryInterval}&queryLevel=${queryLevel}` +
        urlQuery +
        shared +
        topPages +
        limitQ +
        startDateQ +
        endDateQ,
      { headers }
    );
  }

  getWebVital(id: string): Observable<any> {
    return this.httpClient.get(
      `${ConstantService.apiRoute.getVitalData}/${id}`
    );
  }

  getWebvitalDetail(
    userId: string,
    siteId: string,
    queryInterval: string,
    queryLevel: string
  ): Observable<any> {
    return this.httpClient.get(
      `${ConstantService.apiRoute.getLiveDataDetail}/${userId}/${siteId}/${queryInterval}/${queryLevel}`
    );
  }

  analyzeInternalPages(payload) {
    this.auth_token = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token
    );
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.httpClient.post(
      `${ConstantService.apiRoute.analyzeInternalPageOnly}`,
      { ...payload },
      { headers }
    );
  }

  getInternalPagesOnlyList(userId, device) {
    this.auth_token = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token
    );
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.httpClient.get(
      `${ConstantService.apiRoute.speetalsApi}/list-internalpages-only/${userId}/${device}`,
      { headers, context: withCache() }
    );
  }

  getAlertDetail(id: string) {
    this.auth_token = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token
    );
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.httpClient.get(`${ConstantService.apiRoute.diffAlerts}/${id}`, {
      headers,
    });
  }

  getPageVisitOvertime(userId: string, interval: string) {
    this.auth_token = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token
    );
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.httpClient.get(
      `${ConstantService.apiRoute.pageVisitsOverTime}/${userId}/${interval}`,
      {
        headers,
      }
    );
  }

  getWebsiteBar(userId: string): Observable<BarUrl[]> {
    if (
      localStorage.getItem(ConstantService.localStorageKeys.access_Token) !==
      null
    ) {
      this.auth_token = localStorage.getItem(
        ConstantService.localStorageKeys.access_Token
      );
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth_token}`,
      });
      return this.httpClient.get<BarUrl[]>(
        this.apiRoutes.websiteanalyze + '/' + userId,
        { headers: headers, context: withCache() }
      );
    } else {
      this.delay(500);
      this.getWebsiteAnalyze(userId);
    }
  }

  // New rum endpoints
  getListofRumSites(userId, queryInterval) {
    return this.httpClient.get(
      `${ConstantService.apiRoute.rum}/list/${userId}/${queryInterval}`
    );
  }

  getRumOverview(projectId, queryInterval): Observable<any> {
    return this.httpClient.get(
      `${ConstantService.apiRoute.rum}/overview/${projectId}/${queryInterval}`
    );
  }

  getRumMetric(projectId, metric, queryInterval, daysRange?): Observable<any> {
    return this.httpClient.get(
      `${ConstantService.apiRoute.rum}/${projectId}/${metric}/${queryInterval}` +
        (daysRange ? '?daysRange=' + daysRange : '')
    );
  }

  getRumMostVisitedPages(projectId, queryInterval): Observable<any> {
    return this.httpClient.get(
      `${ConstantService.apiRoute.rum}/most-visited-pages/${projectId}/${queryInterval}`
    );
  }

  getRumWorstPerformingPages(
    projectId,
    metric,
    queryInterval,
    location,
    device,
    dimension
  ): Observable<any> {
    return this.httpClient.get(
      `${ConstantService.apiRoute.rum}/worst-performing-pages/${projectId}/${metric}/${queryInterval}?location=${location}&device=${device}&dimension=${dimension}`
    );
  }

  getRumInternalPages(
    projectId,
    queryInterval,
    pageNumber,
    pageSize,
    search = ''
  ): Observable<any> {
    return this.httpClient.get(
      `${ConstantService.apiRoute.rum}/internal-pages/${projectId}/${queryInterval}?searchKeyword=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  }

  getRumInternalPageDetail(
    projectId,
    queryInterval,
    url,
    daysRange?
  ): Observable<any> {
    return this.httpClient.post(
      `${
        ConstantService.apiRoute.rum
      }/internal-page-detail/${projectId}/${queryInterval}${
        daysRange ? '?daysRange=' + daysRange : ''
      }`,
      { url }
    );
  }

  passwordUpdate(userId, password, previousPassword = '') {
    this.auth_token = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token
    );
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.httpClient.patch(
      `${ConstantService.apiRoute.passwordUpdate}`,
      {
        userId,
        password,
        previousPassword,
      },
      { headers }
    );
  }

  getRUMEnabledSitesForUser(userId) {
    this.auth_token = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token
    );
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.httpClient.get(
      `${ConstantService.apiRoute.rum}/get-rum-sites/${userId}`,
      {
        headers,
      }
    );
  }

  postURLSegmentData(data) {
    this.auth_token = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token
    );
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.httpClient.post(
      `${ConstantService.apiRoute.rum}/url-segment`,
      data,
      { headers }
    );
  }

  getURLSegmentsList(userId) {
    return this.httpClient.get(
      `${ConstantService.apiRoute.rum}/get-segment-urls/${userId}`
    );
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  getFormValue(value: any) {
    this.formValue = value;
    return this.formValue;
  }
}
