@if (!loading) {
<div>
  <button
    class="btn btn-sm btn-outline-light button d-flex justify-content-center align-items-center rounded-3 w-100"
    [matMenuTriggerFor]="userMenu"
    (click)="openMenu()"
  >
    <img
      class="gb_Ca m-1"
      [src]="
        (userIdentity.profileGoogle || userIdentity?.profile || {}).photoUrl
      "
      alt=""
      aria-hidden="true"
      data-noaft=""
    />
    @if (userId !== null) {
    <div>
      <p class="user-name fs-6 fw-medium m-1 text-dark">
        {{ (userIdentity.profileGoogle || userIdentity?.profile || {}).name }}
      </p>
    </div>
    }
    <span class="header__title-button">
      <mat-icon
        class="d-flex justify-content-center align-items-center mx-1 text-dark"
      >
        {{ isMenuOpened ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
      </mat-icon>
    </span>
  </button>
  <mat-menu #userMenu="matMenu" xPosition="before" class="p-0 m-2 z-3">
    <button mat-menu-item class="user-menu__item-title p-0">
      <mat-list class="p-0">
        <a [routerLink]="'/home/myAccount'" style="text-decoration: none">
          <mat-list-item
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="p-0 d-flex justify-content-start align-items-center"
          >
            <div class="m-3 d-flex justify-content-start align-items-center">
              <mat-icon>account_circle</mat-icon>
              <span>My Account</span>
            </div>
          </mat-list-item>
        </a>
      </mat-list>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item class="user-menu__item-title p-0">
      <mat-list class="p-0">
        <a (click)="clearData()" style="text-decoration: none">
          <mat-list-item class="p-0">
            <div class="m-3 d-flex justify-content-start align-items-center">
              <mat-icon class="logo-account">logout</mat-icon>
              <span>Sign out</span>
            </div>
          </mat-list-item>
        </a>
      </mat-list>
    </button>
  </mat-menu>
</div>
} @if (loading) {
<button mat-raised-button id="monitor-website-now" (click)="navigate()">
  <p id="montiorButton" style="display: inline; font-weight: bold">
    Monitor your website now!
  </p>
</button>
}
