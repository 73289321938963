import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-Free-msg',
  templateUrl: './Free-msg.component.html',
  styleUrls: ['./Free-msg.component.scss'],
})
export class FreeMsgComponent implements OnInit {
  @Input() Message: string = '';
  constructor() {}
  ngOnInit() {
  }
}
