import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  metrics: Record<string, any> = {
    cls:  {
      name : 'Cumulative Layout Shift', //  'layout_instability.cumulative_layout_shift',

    },
    lcp: {
      name : 'Largest Contentful Paint', //'largest_contentful_paint',

  },
    fid: {
      name : 'First input Delay', //first_input.delay',

    },
    fcp: {
      name : 'First Contentful Paint', //first_contentful_paint',

    },
    ttfb: {
      name : 'Time To First Byte', //experimental.time_to_first_byte',

    },
    inp: {
      name : 'Interaction to Next Paint', //experimental.interaction_to_next_paint',

    },
  };

  constructor() { }

  compareObject(a, b ) {
    if ( a.last_nom < b.last_nom ){
      return -1;
    }
    if ( a.last_nom > b.last_nom ){
      return 1;
    }
    return 0;
  }

  compareWithNumber(a: number, b : number) {
    return a-b;
  }

  capitalizeFirstLetter(type : string ) {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }

  getMetricKey(key : string, type : string){
    //type : cls, fid, lcp, fcp
    //key : good, need, poor
    //type = (type.toLowerCase() === "ttfb") ? 'ttfp' : type;
    return (type.toLowerCase() !== "ttfp") ?
      key + this.capitalizeFirstLetter(type) :
      key + type.toUpperCase()
  }
  getMetricName(metric: string): string {
    return this.metrics[metric].name || 'unknown';
  }
  getMetricSeuil(metric : string) : any {
    return this.metrics[metric].seuil;
  }
}
