import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Email, User } from '../../../../pages/auth/models';
import { AuthService, EmailService } from '../../../../pages/auth/services';
import { routes } from '../../../../consts';
import { BarUrl } from 'src/app/shared/models/user.models';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { SenderVarService } from 'src/app/services/senderVar.service';
import { DataService } from 'src/app/services/common/sidabar/Data.service';
import { SpeetalsDatabaseService } from 'src/app/services/common/speetalsDatabase/speetals-database.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() isMenuOpened: boolean;
  @Output() isShowSidebar = new EventEmitter<boolean>();
  public user$: Observable<User>;
  public emails$: Observable<Email[]>;
  public routers: typeof routes = routes;
  hrefHome: string;
  userId: string;
  BarURL: Array<BarUrl>;
  selected: any;
  country: string;
  selectedList = 'crux';
  isCruxPage = true;
  isRumPage = false;
  showShared: any;
  modeShared;
  sharedWithResult: any;
  device: string;

  constructor(
    private userService: AuthService,
    private emailService: EmailService,
    private websiteAnalyzeService: WebsiteAnalyzeService,
    private router: Router,
    public senderVarService: SenderVarService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private speetalsDatabaseService: SpeetalsDatabaseService
  ) {
    this.user$ = this.userService.getUser();
    this.emails$ = this.emailService.loadEmails();
    if (this.router.url.slice(0, 5) === '/demo') {
      this.hrefHome = '/auth/login';
    } else {
      this.hrefHome = '/home';
    }
    this.router.events.subscribe((e) => {
      this.isCruxPage = !this.router.url.includes('rum');
      this.isRumPage = this.router.url.includes('rum');
    });
  }

  async ngOnInit(): Promise<void> {
    this.device = localStorage.getItem(ConstantService.loadingUrl.device);
    this.senderVarService.id = this.route.snapshot.paramMap.get('id');
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
    this.sharedWithResult = await this.speetalsDatabaseService
      .getDataListpageShared(this.userId, this?.device?.toLowerCase() || 'desktop')
      .toPromise();

    this.BarURL = await this.websiteAnalyzeService
      .getWebsiteBar(this.userId)
      .toPromise();

    let all: BarUrl = {
      id: null,
      originDomain: null,
      country: null,
      title: 'All',
    };

    this.BarURL.unshift(all);
    let i = 0;
    this.BarURL.map((data: BarUrl) => {
      if (data.id === this.senderVarService.id) {
        this.selected = this.BarURL[i];
        this.country = this.BarURL[i].country;
      }

      i++;
    });
    if (this.senderVarService.id === undefined) {
      this.senderVarService.id = this.route.snapshot.paramMap.get('id');
    }

    if (this.userId === 'null')
      localStorage.setItem(
        ConstantService.loadingUrl.Url,
        'https://www.amazon.com/'
      );

    // localStorage.setItem(ConstantService.loadingUrl.device, 'desktop');
    localStorage.setItem(ConstantService.loadingUrl.Url, this.selected);
  }

  get isOnHomeSubpageCRUX(): boolean {
    return this.router.url.startsWith('/home/page/');
  }
  get isOnHomeSubpageRUM(): boolean {
    return this.router.url.startsWith('/home/rum/');
  }
  get isHomeRoute(): boolean {
    const currentRoute = this.router.url;
    return currentRoute === '/home' || currentRoute === '/home/rum';
  }

  public openMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;

    this.isShowSidebar.emit(this.isMenuOpened);
  }

  public signOut(): void {
    this.userService.signOut();

    this.router.navigate([this.routers.LOGIN]);
  }

  navigateBetween(page) {
    this.selectedList = page;
    if (page === 'crux') return this.navigateTo();
    this.selectedList = 'rum';
    this.router.navigate([this.hrefHome + '/rum']);
  }

  navigateTo() {
    this.selectedList = 'crux';
    if (this.router.url != this.hrefHome) {
      this.router.navigate([this.hrefHome]);
    }
  }

}
