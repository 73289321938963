import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class SenderVarService {

  id :string;
  isEdit = false;
  editId : string

constructor() { }


}
