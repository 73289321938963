import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/common/user/user.service';
import { AuthService } from 'src/app/services/core/auth/auth.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { UserIdentity } from 'src/app/shared/models/user.models';

import { routes } from '../../../../consts';
import { User } from '../../../../pages/auth/models';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  @Input() user: User;
  @Output() signOut: EventEmitter<void> = new EventEmitter<void>();
  public routes: typeof routes = routes;
  public flatlogicEmail: string = 'https://flatlogic.com';
  userId: string;
  userIdentity: UserIdentity;
  loading: boolean = true;
  isMenuOpened: boolean = false;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
    if (this.userId !== null)
      this.userService
        .getUserIdentity(this.userId)
        .subscribe((data: UserIdentity) => {
          this.userIdentity = data;
          this.loading = false;
          localStorage.setItem(
            ConstantService.localStorageKeys.role,
            JSON.stringify(data.roles)
          );
          localStorage.setItem(
            ConstantService.localStorageKeys.plan_name,
            data.planName
          );
        });
  }

  public signOutEmit(): void {
    this.signOut.emit();
  }
  async clearData() {
    await this.authService.logoutUser();
  }

  navigate() {
    this.router.navigate(['/auth/login']);
  }
  public openMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;
  }
}
