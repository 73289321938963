import fileSize from "./file-size.js";
import averageIntensity from "../data/average-intensities.min.js";
const KWH_PER_GB = 0.81;
const END_USER_DEVICE_ENERGY = 0.52;
const NETWORK_ENERGY = 0.14;
const DATACENTER_ENERGY = 0.15;
const PRODUCTION_ENERGY = 0.19;
const GLOBAL_GRID_INTENSITY = averageIntensity.data["WORLD"];
const RENEWABLES_GRID_INTENSITY = 50;
const FIRST_TIME_VIEWING_PERCENTAGE = 0.75;
const RETURNING_VISITOR_PERCENTAGE = 0.25;
const PERCENTAGE_OF_DATA_LOADED_ON_SUBSEQUENT_LOAD = 0.02;
export { DATACENTER_ENERGY, END_USER_DEVICE_ENERGY, FIRST_TIME_VIEWING_PERCENTAGE, GLOBAL_GRID_INTENSITY, KWH_PER_GB, NETWORK_ENERGY, PERCENTAGE_OF_DATA_LOADED_ON_SUBSEQUENT_LOAD, PRODUCTION_ENERGY, RENEWABLES_GRID_INTENSITY, RETURNING_VISITOR_PERCENTAGE, fileSize };