export enum routes {
  DASHBOARD = '/dashboard',
  TYPOGRAPHY = '/typography',
  TABLES = '/tables',
  NOTIFICATION = '/notification',
  UI_ELEMENTS_ICONS = '/ui/icons',
  UI_ELEMENTS_CHARTS = '/ui/charts',
  UI_ELEMENTS_MAP = '/ui/map',
  LOGIN = '/login',
  PLANS= '/buyPlan',
  OVERVIEW ='/overview',
  INTERNALPAGES='/internalpages',
  LCP = '/lcp',
  FID='/fid',
  CLS='/cls',
  FCP='/fcp',
  TTFB='/ttfb',
  INP='/inp',
  NAV_TYPE= '/navtype',
  COMPARISON='/competitor',
  NAVTYPE='/navtype'
}
