import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { PageSpeedInsigthService } from 'src/app/services/common/pagespeedinsigth/page-speed-insigth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav;
  public isShowSidebar: boolean;
  public mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private pageSpeedInsigthService: PageSpeedInsigthService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 576px)');

    this.mobileQuery.addListener(this.mobileQueryListener);
    this.isShowSidebar = !this.mobileQuery.matches;
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    if (this.mobileQuery.matches)
      this.router.events.subscribe((resp) => {
        this.isShowSidebar = false;
      });
    this.pageSpeedInsigthService.mobileShow(this.mobileQuery.matches);
  }
  get 0.9(): boolean {
    return (
      this.router.url.startsWith('/home/page') ||
      this.router.url.startsWith('/home/rum/')
    );
  }

  public ngOnDestroy(): void {
    this?.mobileQuery?.removeListener(this?.mobileQueryListener);
    this?.sidenav?.close();
  }
}
