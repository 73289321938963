


export var countries = [
    { alpha2Code: "AF", alpha2Code3: "AFG", name: "Afghanistan", numericalpha2Code: "004" },
    { alpha2Code: "AL", alpha2Code3: "ALB", name: "Albania", numericalpha2Code: "008" },
    { alpha2Code: "DZ", alpha2Code3: "DZA", name: "Algeria", numericalpha2Code: "012" },
    { alpha2Code: "AS", alpha2Code3: "ASM", name: "American Samoa", numericalpha2Code: "016" },
    { alpha2Code: "AD", alpha2Code3: "AND", name: "Andorra", numericalpha2Code: "020" },
    { alpha2Code: "AO", alpha2Code3: "AGO", name: "Angola", numericalpha2Code: "024" },
    { alpha2Code: "AI", alpha2Code3: "AIA", name: "Anguilla", numericalpha2Code: "660" },
    { alpha2Code: "AQ", alpha2Code3: "ATA", name: "Antarctica", numericalpha2Code: "010" },
    { alpha2Code: "AG", alpha2Code3: "ATG", name: "Antigua and Barbuda", numericalpha2Code: "028" },
    { alpha2Code: "AR", alpha2Code3: "ARG", name: "Argentina", numericalpha2Code: "032" },
    { alpha2Code: "AM", alpha2Code3: "ARM", name: "Armenia", numericalpha2Code: "051" },
    { alpha2Code: "AW", alpha2Code3: "ABW", name: "Aruba", numericalpha2Code: "533" },
    { alpha2Code: "AU", alpha2Code3: "AUS", name: "Australia", numericalpha2Code: "036" },
    { alpha2Code: "AT", alpha2Code3: "AUT", name: "Austria", numericalpha2Code: "040" },
    { alpha2Code: "AZ", alpha2Code3: "AZE", name: "Azerbaijan", numericalpha2Code: "031" },
    { alpha2Code: "BS", alpha2Code3: "BHS", name: "Bahamas (the)", numericalpha2Code: "044" },
    { alpha2Code: "BH", alpha2Code3: "BHR", name: "Bahrain", numericalpha2Code: "048" },
    { alpha2Code: "BD", alpha2Code3: "BGD", name: "Bangladesh", numericalpha2Code: "050" },
    { alpha2Code: "BB", alpha2Code3: "BRB", name: "Barbados", numericalpha2Code: "052" },
    { alpha2Code: "BY", alpha2Code3: "BLR", name: "Belarus", numericalpha2Code: "112" },
    { alpha2Code: "BE", alpha2Code3: "BEL", name: "Belgium", numericalpha2Code: "056" },
    { alpha2Code: "BZ", alpha2Code3: "BLZ", name: "Belize", numericalpha2Code: "084" },
    { alpha2Code: "BJ", alpha2Code3: "BEN", name: "Benin", numericalpha2Code: "204" },
    { alpha2Code: "BM", alpha2Code3: "BMU", name: "Bermuda", numericalpha2Code: "060" },
    { alpha2Code: "BT", alpha2Code3: "BTN", name: "Bhutan", numericalpha2Code: "064" },
    { alpha2Code: "BO", alpha2Code3: "BOL", name: "Bolivia (Plurinational State of)", numericalpha2Code: "068" },
    { alpha2Code: "BQ", alpha2Code3: "BES", name: "Bonaire, Sint Eustatius and Saba", numericalpha2Code: "535" },
    { alpha2Code: "BA", alpha2Code3: "BIH", name: "Bosnia and Herzegovina", numericalpha2Code: "070" },
    { alpha2Code: "BW", alpha2Code3: "BWA", name: "Botswana", numericalpha2Code: "072" },
    { alpha2Code: "BV", alpha2Code3: "BVT", name: "Bouvet Island", numericalpha2Code: "074" },
    { alpha2Code: "BR", alpha2Code3: "BRA", name: "Brazil", numericalpha2Code: "076" },
    { alpha2Code: "IO", alpha2Code3: "IOT", name: "British Indian Ocean Territory (the)", numericalpha2Code: "086" },
    { alpha2Code: "BN", alpha2Code3: "BRN", name: "Brunei Darussalam", numericalpha2Code: "096" },
    { alpha2Code: "BG", alpha2Code3: "BGR", name: "Bulgaria", numericalpha2Code: "100" },
    { alpha2Code: "BF", alpha2Code3: "BFA", name: "Burkina Faso", numericalpha2Code: "854" },
    { alpha2Code: "BI", alpha2Code3: "BDI", name: "Burundi", numericalpha2Code: "108" },
    { alpha2Code: "CV", alpha2Code3: "CPV", name: "Cabo Verde", numericalpha2Code: "132" },
    { alpha2Code: "KH", alpha2Code3: "KHM", name: "Cambodia", numericalpha2Code: "116" },
    { alpha2Code: "CM", alpha2Code3: "CMR", name: "Cameroon", numericalpha2Code: "120" },
    { alpha2Code: "CA", alpha2Code3: "CAN", name: "Canada", numericalpha2Code: "124" },
    { alpha2Code: "KY", alpha2Code3: "CYM", name: "Cayman Islands (the)", numericalpha2Code: "136" },
    { alpha2Code: "CF", alpha2Code3: "CAF", name: "Central African Republic (the)", numericalpha2Code: "140" },
    { alpha2Code: "TD", alpha2Code3: "TCD", name: "Chad", numericalpha2Code: "148" },
    { alpha2Code: "CL", alpha2Code3: "CHL", name: "Chile", numericalpha2Code: "152" },
    { alpha2Code: "CN", alpha2Code3: "CHN", name: "China", numericalpha2Code: "156" },
    { alpha2Code: "CX", alpha2Code3: "CXR", name: "Christmas Island", numericalpha2Code: "162" },
    { alpha2Code: "CC", alpha2Code3: "CCK", name: "Cocos (Keeling) Islands (the)", numericalpha2Code: "166" },
    { alpha2Code: "CO", alpha2Code3: "COL", name: "Colombia", numericalpha2Code: "170" },
    { alpha2Code: "KM", alpha2Code3: "COM", name: "Comoros (the)", numericalpha2Code: "174" },
    { alpha2Code: "CD", alpha2Code3: "COD", name: "Congo (the Democratic Republic of the)", numericalpha2Code: "180" },
    { alpha2Code: "CG", alpha2Code3: "COG", name: "Congo (the)", numericalpha2Code: "178" },
    { alpha2Code: "CK", alpha2Code3: "COK", name: "Cook Islands (the)", numericalpha2Code: "184" },
    { alpha2Code: "CR", alpha2Code3: "CRI", name: "Costa Rica", numericalpha2Code: "188" },
    { alpha2Code: "HR", alpha2Code3: "HRV", name: "Croatia", numericalpha2Code: "191" },
    { alpha2Code: "CU", alpha2Code3: "CUB", name: "Cuba", numericalpha2Code: "192" },
    { alpha2Code: "CW", alpha2Code3: "CUW", name: "Curaçao", numericalpha2Code: "531" },
    { alpha2Code: "CY", alpha2Code3: "CYP", name: "Cyprus", numericalpha2Code: "196" },
    { alpha2Code: "CZ", alpha2Code3: "CZE", name: "Czechia", numericalpha2Code: "203" },
    { alpha2Code: "CI", alpha2Code3: "CIV", name: "Côte d'Ivoire", numericalpha2Code: "384" },
    { alpha2Code: "DK", alpha2Code3: "DNK", name: "Denmark", numericalpha2Code: "208" },
    { alpha2Code: "DJ", alpha2Code3: "DJI", name: "Djibouti", numericalpha2Code: "262" },
    { alpha2Code: "DM", alpha2Code3: "DMA", name: "Dominica", numericalpha2Code: "212" },
    { alpha2Code: "DO", alpha2Code3: "DOM", name: "Dominican Republic (the)", numericalpha2Code: "214" },
    { alpha2Code: "EC", alpha2Code3: "ECU", name: "Ecuador", numericalpha2Code: "218" },
    { alpha2Code: "EG", alpha2Code3: "EGY", name: "Egypt", numericalpha2Code: "818" },
    { alpha2Code: "SV", alpha2Code3: "SLV", name: "El Salvador", numericalpha2Code: "222" },
    { alpha2Code: "GQ", alpha2Code3: "GNQ", name: "Equatorial Guinea", numericalpha2Code: "226" },
    { alpha2Code: "ER", alpha2Code3: "ERI", name: "Eritrea", numericalpha2Code: "232" },
    { alpha2Code: "EE", alpha2Code3: "EST", name: "Estonia", numericalpha2Code: "233" },
    { alpha2Code: "SZ", alpha2Code3: "SWZ", name: "Eswatini", numericalpha2Code: "748" },
    { alpha2Code: "ET", alpha2Code3: "ETH", name: "Ethiopia", numericalpha2Code: "231" },
    { alpha2Code: "FK", alpha2Code3: "FLK", name: "Falkland Islands (the) [Malvinas]", numericalpha2Code: "238" },
    { alpha2Code: "FO", alpha2Code3: "FRO", name: "Faroe Islands (the)", numericalpha2Code: "234" },
    { alpha2Code: "FJ", alpha2Code3: "FJI", name: "Fiji", numericalpha2Code: "242" },
    { alpha2Code: "FI", alpha2Code3: "FIN", name: "Finland", numericalpha2Code: "246" },
    { alpha2Code: "FR", alpha2Code3: "FRA", name: "France", numericalpha2Code: "250" },
    { alpha2Code: "GF", alpha2Code3: "GUF", name: "French Guiana", numericalpha2Code: "254" },
    { alpha2Code: "PF", alpha2Code3: "PYF", name: "French Polynesia", numericalpha2Code: "258" },
    { alpha2Code: "TF", alpha2Code3: "ATF", name: "French Southern Territories (the)", numericalpha2Code: "260" },
    { alpha2Code: "GA", alpha2Code3: "GAB", name: "Gabon", numericalpha2Code: "266" },
    { alpha2Code: "GM", alpha2Code3: "GMB", name: "Gambia (the)", numericalpha2Code: "270" },
    { alpha2Code: "GE", alpha2Code3: "GEO", name: "Georgia", numericalpha2Code: "268" },
    { alpha2Code: "DE", alpha2Code3: "DEU", name: "Germany", numericalpha2Code: "276" },
    { alpha2Code: "GH", alpha2Code3: "GHA", name: "Ghana", numericalpha2Code: "288" },
    { alpha2Code: "GI", alpha2Code3: "GIB", name: "Gibraltar", numericalpha2Code: "292" },
    { alpha2Code: "GR", alpha2Code3: "GRC", name: "Greece", numericalpha2Code: "300" },
    { alpha2Code: "GL", alpha2Code3: "GRL", name: "Greenland", numericalpha2Code: "304" },
    { alpha2Code: "GD", alpha2Code3: "GRD", name: "Grenada", numericalpha2Code: "308" },
    { alpha2Code: "GP", alpha2Code3: "GLP", name: "Guadeloupe", numericalpha2Code: "312" },
    { alpha2Code: "GU", alpha2Code3: "GUM", name: "Guam", numericalpha2Code: "316" },
    { alpha2Code: "GT", alpha2Code3: "GTM", name: "Guatemala", numericalpha2Code: "320" },
    { alpha2Code: "GG", alpha2Code3: "GGY", name: "Guernsey", numericalpha2Code: "831" },
    { alpha2Code: "GN", alpha2Code3: "GIN", name: "Guinea", numericalpha2Code: "324" },
    { alpha2Code: "GW", alpha2Code3: "GNB", name: "Guinea-Bissau", numericalpha2Code: "624" },
    { alpha2Code: "GY", alpha2Code3: "GUY", name: "Guyana", numericalpha2Code: "328" },
    { alpha2Code: "HT", alpha2Code3: "HTI", name: "Haiti", numericalpha2Code: "332" },
    { alpha2Code: "HM", alpha2Code3: "HMD", name: "Heard Island and McDonald Islands", numericalpha2Code: "334" },
    { alpha2Code: "VA", alpha2Code3: "VAT", name: "Holy See (the)", numericalpha2Code: "336" },
    { alpha2Code: "HN", alpha2Code3: "HND", name: "Honduras", numericalpha2Code: "340" },
    { alpha2Code: "HK", alpha2Code3: "HKG", name: "Hong Kong", numericalpha2Code: "344" },
    { alpha2Code: "HU", alpha2Code3: "HUN", name: "Hungary", numericalpha2Code: "348" },
    { alpha2Code: "IS", alpha2Code3: "ISL", name: "Iceland", numericalpha2Code: "352" },
    { alpha2Code: "IN", alpha2Code3: "IND", name: "India", numericalpha2Code: "356" },
    { alpha2Code: "ID", alpha2Code3: "IDN", name: "Indonesia", numericalpha2Code: "360" },
    { alpha2Code: "IR", alpha2Code3: "IRN", name: "Iran (Islamic Republic of)", numericalpha2Code: "364" },
    { alpha2Code: "IQ", alpha2Code3: "IRQ", name: "Iraq", numericalpha2Code: "368" },
    { alpha2Code: "IE", alpha2Code3: "IRL", name: "Ireland", numericalpha2Code: "372" },
    { alpha2Code: "IM", alpha2Code3: "IMN", name: "Isle of Man", numericalpha2Code: "833" },
    //{ alpha2Code: "IL", alpha2Code3: "ISR", name: "Israel", numericalpha2Code: "376" },
    { alpha2Code: "IT", alpha2Code3: "ITA", name: "Italy", numericalpha2Code: "380" },
    { alpha2Code: "JM", alpha2Code3: "JAM", name: "Jamaica", numericalpha2Code: "388" },
    { alpha2Code: "JP", alpha2Code3: "JPN", name: "Japan", numericalpha2Code: "392" },
    { alpha2Code: "JE", alpha2Code3: "JEY", name: "Jersey", numericalpha2Code: "832" },
    { alpha2Code: "JO", alpha2Code3: "JOR", name: "Jordan", numericalpha2Code: "400" },
    { alpha2Code: "KZ", alpha2Code3: "KAZ", name: "Kazakhstan", numericalpha2Code: "398" },
    { alpha2Code: "KE", alpha2Code3: "KEN", name: "Kenya", numericalpha2Code: "404" },
    { alpha2Code: "KI", alpha2Code3: "KIR", name: "Kiribati", numericalpha2Code: "296" },
    { alpha2Code: "KP", alpha2Code3: "PRK", name: "Korea (the Democratic People's Republic of)", numericalpha2Code: "408" },
    { alpha2Code: "KR", alpha2Code3: "KOR", name: "Korea (the Republic of)", numericalpha2Code: "410" },
    { alpha2Code: "KW", alpha2Code3: "KWT", name: "Kuwait", numericalpha2Code: "414" },
    { alpha2Code: "KG", alpha2Code3: "KGZ", name: "Kyrgyzstan", numericalpha2Code: "417" },
    { alpha2Code: "LA", alpha2Code3: "LAO", name: "Lao People's Democratic Republic (the)", numericalpha2Code: "418" },
    { alpha2Code: "LV", alpha2Code3: "LVA", name: "Latvia", numericalpha2Code: "428" },
    { alpha2Code: "LB", alpha2Code3: "LBN", name: "Lebanon", numericalpha2Code: "422" },
    { alpha2Code: "LS", alpha2Code3: "LSO", name: "Lesotho", numericalpha2Code: "426" },
    { alpha2Code: "LR", alpha2Code3: "LBR", name: "Liberia", numericalpha2Code: "430" },
    { alpha2Code: "LY", alpha2Code3: "LBY", name: "Libya", numericalpha2Code: "434" },
    { alpha2Code: "LI", alpha2Code3: "LIE", name: "Liechtenstein", numericalpha2Code: "438" },
    { alpha2Code: "LT", alpha2Code3: "LTU", name: "Lithuania", numericalpha2Code: "440" },
    { alpha2Code: "LU", alpha2Code3: "LUX", name: "Luxembourg", numericalpha2Code: "442" },
    { alpha2Code: "MO", alpha2Code3: "MAC", name: "Macao", numericalpha2Code: "446" },
    { alpha2Code: "MG", alpha2Code3: "MDG", name: "Madagascar", numericalpha2Code: "450" },
    { alpha2Code: "MW", alpha2Code3: "MWI", name: "Malawi", numericalpha2Code: "454" },
    { alpha2Code: "MY", alpha2Code3: "MYS", name: "Malaysia", numericalpha2Code: "458" },
    { alpha2Code: "MV", alpha2Code3: "MDV", name: "Maldives", numericalpha2Code: "462" },
    { alpha2Code: "ML", alpha2Code3: "MLI", name: "Mali", numericalpha2Code: "466" },
    { alpha2Code: "MT", alpha2Code3: "MLT", name: "Malta", numericalpha2Code: "470" },
    { alpha2Code: "MH", alpha2Code3: "MHL", name: "Marshall Islands (the)", numericalpha2Code: "584" },
    { alpha2Code: "MQ", alpha2Code3: "MTQ", name: "Martinique", numericalpha2Code: "474" },
    { alpha2Code: "MR", alpha2Code3: "MRT", name: "Mauritania", numericalpha2Code: "478" },
    { alpha2Code: "MU", alpha2Code3: "MUS", name: "Mauritius", numericalpha2Code: "480" },
    { alpha2Code: "YT", alpha2Code3: "MYT", name: "Mayotte", numericalpha2Code: "175" },
    { alpha2Code: "MX", alpha2Code3: "MEX", name: "Mexico", numericalpha2Code: "484" },
    { alpha2Code: "FM", alpha2Code3: "FSM", name: "Micronesia (Federated States of)", numericalpha2Code: "583" },
    { alpha2Code: "MD", alpha2Code3: "MDA", name: "Moldova (the Republic of)", numericalpha2Code: "498" },
    { alpha2Code: "MC", alpha2Code3: "MCO", name: "Monaco", numericalpha2Code: "492" },
    { alpha2Code: "MN", alpha2Code3: "MNG", name: "Mongolia", numericalpha2Code: "496" },
    { alpha2Code: "ME", alpha2Code3: "MNE", name: "Montenegro", numericalpha2Code: "499" },
    { alpha2Code: "MS", alpha2Code3: "MSR", name: "Montserrat", numericalpha2Code: "500" },
    { alpha2Code: "MA", alpha2Code3: "MAR", name: "Morocco", numericalpha2Code: "504" },
    { alpha2Code: "MZ", alpha2Code3: "MOZ", name: "Mozambique", numericalpha2Code: "508" },
    { alpha2Code: "MM", alpha2Code3: "MMR", name: "Myanmar", numericalpha2Code: "104" },
    { alpha2Code: "NA", alpha2Code3: "NAM", name: "Namibia", numericalpha2Code: "516" },
    { alpha2Code: "NR", alpha2Code3: "NRU", name: "Nauru", numericalpha2Code: "520" },
    { alpha2Code: "NP", alpha2Code3: "NPL", name: "Nepal", numericalpha2Code: "524" },
    { alpha2Code: "NL", alpha2Code3: "NLD", name: "Netherlands (the)", numericalpha2Code: "528" },
    { alpha2Code: "NC", alpha2Code3: "NCL", name: "New Caledonia", numericalpha2Code: "540" },
    { alpha2Code: "NZ", alpha2Code3: "NZL", name: "New Zealand", numericalpha2Code: "554" },
    { alpha2Code: "NI", alpha2Code3: "NIC", name: "Nicaragua", numericalpha2Code: "558" },
    { alpha2Code: "NE", alpha2Code3: "NER", name: "Niger (the)", numericalpha2Code: "562" },
    { alpha2Code: "NG", alpha2Code3: "NGA", name: "Nigeria", numericalpha2Code: "566" },
    { alpha2Code: "NU", alpha2Code3: "NIU", name: "Niue", numericalpha2Code: "570" },
    { alpha2Code: "NF", alpha2Code3: "NFK", name: "Norfolk Island", numericalpha2Code: "574" },
    { alpha2Code: "MP", alpha2Code3: "MNP", name: "Northern Mariana Islands (the)", numericalpha2Code: "580" },
    { alpha2Code: "NO", alpha2Code3: "NOR", name: "Norway", numericalpha2Code: "578" },
    { alpha2Code: "OM", alpha2Code3: "OMN", name: "Oman", numericalpha2Code: "512" },
    { alpha2Code: "PK", alpha2Code3: "PAK", name: "Pakistan", numericalpha2Code: "586" },
    { alpha2Code: "PW", alpha2Code3: "PLW", name: "Palau", numericalpha2Code: "585" },
    { alpha2Code: "PS", alpha2Code3: "PSE", name: "Palestine, State of", numericalpha2Code: "275" },
    { alpha2Code: "PA", alpha2Code3: "PAN", name: "Panama", numericalpha2Code: "591" },
    { alpha2Code: "PG", alpha2Code3: "PNG", name: "Papua New Guinea", numericalpha2Code: "598" },
    { alpha2Code: "PY", alpha2Code3: "PRY", name: "Paraguay", numericalpha2Code: "600" },
    { alpha2Code: "PE", alpha2Code3: "PER", name: "Peru", numericalpha2Code: "604" },
    { alpha2Code: "PH", alpha2Code3: "PHL", name: "Philippines (the)", numericalpha2Code: "608" },
    { alpha2Code: "PN", alpha2Code3: "PCN", name: "Pitcairn", numericalpha2Code: "612" },
    { alpha2Code: "PL", alpha2Code3: "POL", name: "Poland", numericalpha2Code: "616" },
    { alpha2Code: "PT", alpha2Code3: "PRT", name: "Portugal", numericalpha2Code: "620" },
    { alpha2Code: "PR", alpha2Code3: "PRI", name: "Puerto Rico", numericalpha2Code: "630" },
    { alpha2Code: "QA", alpha2Code3: "QAT", name: "Qatar", numericalpha2Code: "634" },
    { alpha2Code: "MK", alpha2Code3: "MKD", name: "Republic of North Macedonia", numericalpha2Code: "807" },
    { alpha2Code: "RO", alpha2Code3: "ROU", name: "Romania", numericalpha2Code: "642" },
    { alpha2Code: "RU", alpha2Code3: "RUS", name: "Russian Federation (the)", numericalpha2Code: "643" },
    { alpha2Code: "RW", alpha2Code3: "RWA", name: "Rwanda", numericalpha2Code: "646" },
    { alpha2Code: "RE", alpha2Code3: "REU", name: "Réunion", numericalpha2Code: "638" },
    { alpha2Code: "BL", alpha2Code3: "BLM", name: "Saint Barthélemy", numericalpha2Code: "652" },
    { alpha2Code: "SH", alpha2Code3: "SHN", name: "Saint Helena, Ascension and Tristan da Cunha", numericalpha2Code: "654" },
    { alpha2Code: "KN", alpha2Code3: "KNA", name: "Saint Kitts and Nevis", numericalpha2Code: "659" },
    { alpha2Code: "LC", alpha2Code3: "LCA", name: "Saint Lucia", numericalpha2Code: "662" },
    { alpha2Code: "MF", alpha2Code3: "MAF", name: "Saint Martin (French part)", numericalpha2Code: "663" },
    { alpha2Code: "PM", alpha2Code3: "SPM", name: "Saint Pierre and Miquelon", numericalpha2Code: "666" },
    { alpha2Code: "VC", alpha2Code3: "VCT", name: "Saint Vincent and the Grenadines", numericalpha2Code: "670" },
    { alpha2Code: "WS", alpha2Code3: "WSM", name: "Samoa", numericalpha2Code: "882" },
    { alpha2Code: "SM", alpha2Code3: "SMR", name: "San Marino", numericalpha2Code: "674" },
    { alpha2Code: "ST", alpha2Code3: "STP", name: "Sao Tome and Principe", numericalpha2Code: "678" },
    { alpha2Code: "SA", alpha2Code3: "SAU", name: "Saudi Arabia", numericalpha2Code: "682" },
    { alpha2Code: "SN", alpha2Code3: "SEN", name: "Senegal", numericalpha2Code: "686" },
    { alpha2Code: "RS", alpha2Code3: "SRB", name: "Serbia", numericalpha2Code: "688" },
    { alpha2Code: "SC", alpha2Code3: "SYC", name: "Seychelles", numericalpha2Code: "690" },
    { alpha2Code: "SL", alpha2Code3: "SLE", name: "Sierra Leone", numericalpha2Code: "694" },
    { alpha2Code: "SG", alpha2Code3: "SGP", name: "Singapore", numericalpha2Code: "702" },
    { alpha2Code: "SX", alpha2Code3: "SXM", name: "Sint Maarten (Dutch part)", numericalpha2Code: "534" },
    { alpha2Code: "SK", alpha2Code3: "SVK", name: "Slovakia", numericalpha2Code: "703" },
    { alpha2Code: "SI", alpha2Code3: "SVN", name: "Slovenia", numericalpha2Code: "705" },
    { alpha2Code: "SB", alpha2Code3: "SLB", name: "Solomon Islands", numericalpha2Code: "090" },
    { alpha2Code: "SO", alpha2Code3: "SOM", name: "Somalia", numericalpha2Code: "706" },
    { alpha2Code: "ZA", alpha2Code3: "ZAF", name: "South Africa", numericalpha2Code: "710" },
    { alpha2Code: "GS", alpha2Code3: "SGS", name: "South Georgia and the South Sandwich Islands", numericalpha2Code: "239" },
    { alpha2Code: "SS", alpha2Code3: "SSD", name: "South Sudan", numericalpha2Code: "728" },
    { alpha2Code: "ES", alpha2Code3: "ESP", name: "Spain", numericalpha2Code: "724" },
    { alpha2Code: "LK", alpha2Code3: "LKA", name: "Sri Lanka", numericalpha2Code: "144" },
    { alpha2Code: "SD", alpha2Code3: "SDN", name: "Sudan (the)", numericalpha2Code: "729" },
    { alpha2Code: "SR", alpha2Code3: "SUR", name: "Suriname", numericalpha2Code: "740" },
    { alpha2Code: "SJ", alpha2Code3: "SJM", name: "Svalbard and Jan Mayen", numericalpha2Code: "744" },
    { alpha2Code: "SE", alpha2Code3: "SWE", name: "Sweden", numericalpha2Code: "752" },
    { alpha2Code: "CH", alpha2Code3: "CHE", name: "Switzerland", numericalpha2Code: "756" },
    { alpha2Code: "SY", alpha2Code3: "SYR", name: "Syrian Arab Republic", numericalpha2Code: "760" },
    { alpha2Code: "TW", alpha2Code3: "TWN", name: "Taiwan", numericalpha2Code: "158" },
    { alpha2Code: "TJ", alpha2Code3: "TJK", name: "Tajikistan", numericalpha2Code: "762" },
    { alpha2Code: "TZ", alpha2Code3: "TZA", name: "Tanzania, United Republic of", numericalpha2Code: "834" },
    { alpha2Code: "TH", alpha2Code3: "THA", name: "Thailand", numericalpha2Code: "764" },
    { alpha2Code: "TL", alpha2Code3: "TLS", name: "Timor-Leste", numericalpha2Code: "626" },
    { alpha2Code: "TG", alpha2Code3: "TGO", name: "Togo", numericalpha2Code: "768" },
    { alpha2Code: "TK", alpha2Code3: "TKL", name: "Tokelau", numericalpha2Code: "772" },
    { alpha2Code: "TO", alpha2Code3: "TON", name: "Tonga", numericalpha2Code: "776" },
    { alpha2Code: "TT", alpha2Code3: "TTO", name: "Trinidad and Tobago", numericalpha2Code: "780" },
    { alpha2Code: "TN", alpha2Code3: "TUN", name: "Tunisia", numericalpha2Code: "788" },
    { alpha2Code: "TR", alpha2Code3: "TUR", name: "Turkey", numericalpha2Code: "792" },
    { alpha2Code: "TM", alpha2Code3: "TKM", name: "Turkmenistan", numericalpha2Code: "795" },
    { alpha2Code: "TC", alpha2Code3: "TCA", name: "Turks and Caicos Islands (the)", numericalpha2Code: "796" },
    { alpha2Code: "TV", alpha2Code3: "TUV", name: "Tuvalu", numericalpha2Code: "798" },
    { alpha2Code: "UG", alpha2Code3: "UGA", name: "Uganda", numericalpha2Code: "800" },
    { alpha2Code: "UA", alpha2Code3: "UKR", name: "Ukraine", numericalpha2Code: "804" },
    { alpha2Code: "AE", alpha2Code3: "ARE", name: "United Arab Emirates (the)", numericalpha2Code: "784" },
    { alpha2Code: "GB", alpha2Code3: "GBR", name: "United Kingdom of Great Britain and Northern Ireland (the)", numericalpha2Code: "826" },
    { alpha2Code: "UM", alpha2Code3: "UMI", name: "United States Minor Outlying Islands (the)", numericalpha2Code: "581" },
    { alpha2Code: "US", alpha2Code3: "USA", name: "United States of America", numericalpha2Code: "840" },
    { alpha2Code: "UY", alpha2Code3: "URY", name: "Uruguay", numericalpha2Code: "858" },
    { alpha2Code: "UZ", alpha2Code3: "UZB", name: "Uzbekistan", numericalpha2Code: "860" },
    { alpha2Code: "VU", alpha2Code3: "VUT", name: "Vanuatu", numericalpha2Code: "548" },
    { alpha2Code: "VE", alpha2Code3: "VEN", name: "Venezuela (Bolivarian Republic of)", numericalpha2Code: "862" },
    { alpha2Code: "VN", alpha2Code3: "VNM", name: "Viet Nam", numericalpha2Code: "704" },
    { alpha2Code: "VG", alpha2Code3: "VGB", name: "Virgin Islands (British)", numericalpha2Code: "092" },
    { alpha2Code: "VI", alpha2Code3: "VIR", name: "Virgin Islands (U.S.)", numericalpha2Code: "850" },
    { alpha2Code: "WF", alpha2Code3: "WLF", name: "Wallis and Futuna", numericalpha2Code: "876" },
    { alpha2Code: "EH", alpha2Code3: "ESH", name: "Western Sahara", numericalpha2Code: "732" },
    { alpha2Code: "YE", alpha2Code3: "YEM", name: "Yemen", numericalpha2Code: "887" },
    { alpha2Code: "ZM", alpha2Code3: "ZMB", name: "Zambia", numericalpha2Code: "894" },
    { alpha2Code: "ZW", alpha2Code3: "ZWE", name: "Zimbabwe", numericalpha2Code: "716" },
    { alpha2Code: "AX", alpha2Code3: "ALA", name: "Åland Islands", numericalpha2Code: "248" }
];
