import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConstantService {
  constructor() {}
  static apiRoute = {
    login: `${environment.apiUrl}/auth/login`,
    signup: `${environment.apiUrl}/auth/signup`,
    passwordUpdate: `${environment.apiUrl}/auth/credentials`,
    websiteanalyze: `${environment.serverUrl}/website-analyzes`,
    pageSpeedData: `${environment.serverUrl}/api/speetalsDatabases`,
    speetalsApi: `${environment.serverUrl}/api/speetals-databases`,
    firstPartyMetricData: `${environment.serverUrl}/getFirstPartyVitals`,
    attributionData: `${environment.serverUrl}/attribution`,
    getVitalData: `${environment.serverUrl}/getVitalData`,
    getLiveDataDetail: `${environment.serverUrl}/getLiveVitalDetail`,
    cruxApi: `${environment.serverUrl}/cruxApis`,
    logout: `${environment.apiUrl}/auth/logout`,
    pageSpeed: `${environment.apiPageSpeedUrl}`,
    registerGoogleUser: `${environment.serverUrl}/user-identities`,
    getUserDetail: `${environment.serverUrl}/user`,
    annotation: `${environment.serverUrl}/api/annotations`,
    analyzeGuestWeb: `${environment.serverUrl}/analyze-guest-web`,
    saveLoggedUserData: `${environment.serverUrl}/save-analyze-result`,
    analyzeInternalPageOnly: `${environment.serverUrl}/analyze-internal-pages`,
    diffAlerts: `${environment.serverUrl}/diff-alerts`,
    updateRUMSettings: `${environment.serverUrl}/update-rum-settings`,
    pageVisitsOverTime: `${environment.serverUrl}/rum-page-views-overtime`,
    rum: `${environment.serverUrl}/rum`,
  };
  static apikey = {
    pageSpeed: `AIzaSyAOcFw_s06N37wbfpK6e4qYSt0eih0b4EA`,
  };
  static apiMethod = {
    get: 'get',
    post: 'post',
    put: 'put',
    delete: 'delete',
  };
  static localStorageKeys = {
    Url: 'Url',
    projectID: 'projectID',
    accessToken: 'accessToken',
    access_Token: 'access_Token',
    refresh_Token: 'refresh_Token',
    access_Token_Google: 'access_Token_Google',
    pagesData: 'pagesData',
    userId: 'userId',
    email: 'email',
    provider: 'provider',
    refreshToken: 'refreshToken',
    role: 'userRoles',
    seenDemo: 'seen_demo',
    slackStateCode: 'SLACK_STATE_CODE',
    diff_date: 'diff_date',
    plan_name: 'planName',
    sharedMode: 'sharedMode',
    sharedRUMProjectId: 'sharedRUMProjectId',
    selectedRUMCountry: 'selectedRUMCountry',
  };
  static loadingUrl = {
    Url: 'Url',
    loading: 'Loading_Url',
    loadingUrl: 'Loading_Url',
    id: 'id',
    country: 'country',
    switch: 'switch',
    title: 'title',
    countInternalUrl: 'countInternalUrl',
    device: 'device',
    show: 'show',
    awaitTime: 'awaitTime',
    fpdDimGroup: 'fpdDimGroup',
    fpdDimension: 'fpdDimension',
    fpdQueryPeriod: 'fpdQueryPerid',
    fpdDims: 'fpdDims',
  };
  // static InternalPage = {
  //   loading: 'Loading_Url_InternalPage',
  // }
  static defaultDevices = ['desktop', 'mobile', 'tablet'];
  static defaultDimensionGroup = {
    desktop: [
      {
        id: 'effectiveConnectionType',
        name: 'Effective Connection Type',
        dimensions: ['4g', '3g', '2g', 'slow-2g'],
      },
      {
        id: 'browserName',
        name: 'Browser',
        dimensions: ['Chrome', 'Firefox', 'Safari'],
      },
      {
        id: 'os',
        name: 'Operating system',
        dimensions: ['Linux', 'Windows', 'MacOs'],
      },
    ],
    mobile: [
      {
        id: 'effectiveConnectionType',
        name: 'Effective Connection Type',
        dimensions: ['4g', '3g', '2g', 'slow-2g'],
      },
      {
        id: 'browserName',
        name: 'Browser',
        dimensions: ['Chrome', 'Firefox', 'Safari'],
      },
      {
        id: 'os',
        name: 'Operating system',
        dimensions: ['Andorid', 'iOS'],
      },
    ],
    tablet: [
      {
        id: 'effectiveConnectionType',
        name: 'Effective Connection Type',
        dimensions: ['4g', '3g', '2g', 'slow-2g'],
      },
      {
        id: 'browserName',
        name: 'Browser',
        dimensions: ['Chrome', 'Firefox', 'Safari'],
      },
      {
        id: 'os',
        name: 'Operating system',
        dimensions: ['Andorid', 'iOS'],
      },
    ],
  };
  static errorMessage = {
    noEmailExist: 'Invalid Information',
    unknownError: 'Unknow Error, Please try again',
    formError: 'Form Error',
    checkEmail: 'Email is sent to you',
    websiteExists: 'website with the same location exists',
    notVerifed: 'Email not verified',
    currrentPassword: 'Invalide current password',
    unknownUser: 'Unknown User',
    websiteAnalalyseNotAvailable: 'Website analyse not available',
  };
  static successMessage = {
    userloggedIn: 'You Are Logged In',
    userloggedOut: 'You Are Logged Out',
    createAccount: 'You Have Successfully Created Your Account',
  };
  static titleGraphicimage = {
    good: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAARCAIAAACjLUBkAAAAA3NCSVQICAjb4U/gAAAA3ElEQVQ4jWP8//8/A1UBE3WNo4mJLGj8BV9OL/x85sKvZwwMDAZsUgm8pvE8JiSZyAgPxw//fgS+nH/gxz00FQ4cSuvFEwWYOEg20eH59IMYxsEN3S+ZSaSJ0HBc8OU0LuMYGBgO/Li34MtpEk38fAa/OoIK0E288PMpfnUXfz0jzUSCgPiMADXRgF0avzqCCtBNTOAlkOgIKsAwkcfUnkMJlyJ7DqUEHlPSTGRgYNggnojVUHsOpQ3iiUQax4CcwiFgwZfTCz6fgUS9Abt0Aq8J8a7DbiLlgPplDwCP+E4yJIzvQwAAAABJRU5ErkJggg==',
    poor: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAQCAIAAAByTXNPAAAAA3NCSVQICAjb4U/gAAAAdklEQVQ4jc3TwQ2AIAyF4bZxHmdwNBwG56grEJ0HD4JBAsZnL/bU9PDnu5RjjGQesSdaFQ2kAa0M9cF5YqZpNFg00LqTbijnXnE+LfPytXJC0o5xisoFwTm5UkJwTq5UEJAjbQjIkS4E4UgXgnDkCfKawz/66QMKYjXWOjf5uwAAAABJRU5ErkJggg==',
    average:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAASCAIAAAA/hdJEAAAAA3NCSVQICAjb4U/gAAAAL0lEQVQ4jWP8//8/A8WAiXIjBpkpLAwMDAwLzfEpiT9JJ7eMmjJqCv1NYRx2JQMA/OQIH2gK+YwAAAAASUVORK5CYII=',
  };

  static speedColors = {
    FAST: '#43B399',
    AVERAGE: '#F6AD45',
    SLOW: '#EF6F52',
  };
}
