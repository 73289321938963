const data = {
  "AFG": 120.48,
  "AFRICA": 484.7,
  "ALB": 23.44,
  "DZA": 485.49,
  "ASM": 687.5,
  "AGO": 195.98,
  "ATG": 657.14,
  "ARG": 344.31,
  "ARM": 222.68,
  "ABW": 591.4,
  "ASEAN": 508.2,
  "ASIA": 534.83,
  "AUS": 501.7,
  "AUT": 158.22,
  "AZE": 469.58,
  "BHS": 698.11,
  "BHR": 494.02,
  "BGD": 574.28,
  "BRB": 644.86,
  "BLR": 425.9,
  "BEL": 167.11,
  "BLZ": 484.38,
  "BEN": 666.67,
  "BTN": 24.44,
  "BOL": 335.4,
  "BIH": 553.47,
  "BWA": 794.52,
  "BRA": 102.04,
  "BRN": 493.59,
  "BGR": 399.72,
  "BFA": 611.43,
  "BDI": 250,
  "CPV": 600,
  "KHM": 400.46,
  "CMR": 278.26,
  "CAN": 125.84,
  "CYM": 684.93,
  "CAF": 0,
  "TCD": 677.42,
  "CHL": 332.61,
  "CHN": 533.98,
  "COL": 163.99,
  "COM": 714.29,
  "COG": 395.52,
  "COD": 25.36,
  "COK": 400,
  "CRI": 37.21,
  "CIV": 410.75,
  "HRV": 246.29,
  "CUB": 654.68,
  "CYP": 589.35,
  "CZE": 414.8,
  "DNK": 180.42,
  "DJI": 666.67,
  "DMA": 529.41,
  "DOM": 549.8,
  "ECU": 183.63,
  "EGY": 469.63,
  "SLV": 194.23,
  "GNQ": 492.96,
  "ERI": 688.89,
  "EST": 460.26,
  "SWZ": 189.19,
  "ETH": 25.19,
  "EU": 276.63,
  "EUROPE": 297.05,
  "FLK": 500,
  "FRO": 428.57,
  "FJI": 289.47,
  "FIN": 131.71,
  "FRA": 84.88,
  "GUF": 254.72,
  "PYF": 471.43,
  "G20": 442.57,
  "G7": 344.31,
  "GAB": 397.38,
  "GMB": 700,
  "GEO": 134.83,
  "DEU": 385.39,
  "GHA": 361.2,
  "GRC": 344.41,
  "GRL": 133.33,
  "GRD": 714.29,
  "GLP": 623.53,
  "GUM": 670.33,
  "GTM": 304.71,
  "GIN": 208.63,
  "GNB": 750,
  "GUY": 642.28,
  "HTI": 606.06,
  "HND": 373.96,
  "HKG": 609.93,
  "HUN": 222.1,
  "ISL": 28.56,
  "IND": 633.4,
  "IDN": 619.03,
  "IRN": 487.86,
  "IRQ": 531.36,
  "IRL": 346.43,
  "ISR": 537.57,
  "ITA": 372.63,
  "JAM": 537.93,
  "JPN": 494.86,
  "JOR": 391.13,
  "KAZ": 635.57,
  "KEN": 101.13,
  "KIR": 666.67,
  "XKX": 767,
  "KWT": 574.56,
  "KGZ": 104.43,
  "LAO": 242.18,
  "LATIN AMERICA AND CARIBBEAN": 237.91,
  "LVA": 183.43,
  "LBN": 663.1,
  "LSO": 20,
  "LBR": 304.35,
  "LBY": 558.85,
  "LTU": 195.7,
  "LUX": 162.6,
  "MAC": 491.53,
  "MDG": 483.25,
  "MWI": 133.8,
  "MYS": 543.87,
  "MDV": 651.52,
  "MLI": 463.13,
  "MLT": 433.48,
  "MTQ": 698.63,
  "MRT": 526.6,
  "MUS": 611.11,
  "MEX": 423.81,
  "MIDDLE EAST": 519.92,
  "MDA": 666.67,
  "MNG": 642.37,
  "MNE": 392.75,
  "MSR": 1e3,
  "MAR": 630.75,
  "MOZ": 126.63,
  "MMR": 330.8,
  "NAM": 63.69,
  "NRU": 750,
  "NPL": 24.51,
  "NLD": 354.31,
  "NCL": 610.12,
  "NZL": 105.22,
  "NIC": 354.21,
  "NER": 622.22,
  "NGA": 368.11,
  "NORTH AMERICA": 336.68,
  "PRK": 102.42,
  "MKD": 543.71,
  "NOR": 28.93,
  "OCEANIA": 450.73,
  "OECD": 341.08,
  "OMN": 488.27,
  "PAK": 344.16,
  "PSE": 465.12,
  "PAN": 152.68,
  "PNG": 526.75,
  "PRY": 25.49,
  "PER": 256.51,
  "POL": 633.23,
  "PRT": 234.61,
  "PRI": 612.39,
  "QAT": 490.28,
  "REU": 519.03,
  "ROU": 264.24,
  "RUS": 363.68,
  "RWA": 294.12,
  "KNA": 681.82,
  "LCA": 685.71,
  "SPM": 800,
  "VCT": 500,
  "WSM": 470.59,
  "STP": 600,
  "SAU": 557.78,
  "SEN": 523.13,
  "SRB": 582.13,
  "SYC": 615.39,
  "SLE": 47.62,
  "SGP": 488.78,
  "SVK": 140.14,
  "SVN": 237.38,
  "SLB": 727.27,
  "SOM": 634.15,
  "ZAF": 708.21,
  "KOR": 437.6,
  "SSD": 684.21,
  "ESP": 217.42,
  "LKA": 501.53,
  "SDN": 288.13,
  "SUR": 356.44,
  "SWE": 45.12,
  "CHE": 41.28,
  "SYR": 541.17,
  "TWN": 560.98,
  "TJK": 83.63,
  "TZA": 366.75,
  "THA": 501.57,
  "PHL": 594.45,
  "TGO": 460.32,
  "TON": 625,
  "TTO": 491.41,
  "TUN": 469.43,
  "TUR": 413.6,
  "TKM": 490.19,
  "TCA": 703.7,
  "UGA": 52.27,
  "UKR": 232.74,
  "ARE": 407.98,
  "GBR": 261.16,
  "USA": 368.1,
  "URY": 150.13,
  "UZB": 505.41,
  "VUT": 571.43,
  "VEN": 212.48,
  "VNM": 386.49,
  "VGB": 714.29,
  "VIR": 685.71,
  "WORLD": 437.63,
  "YEM": 559.66,
  "ZMB": 84.7,
  "ZWE": 392.28
};
const type = "average";
var average_intensities_min_default = {
  data,
  type
};
export { data, average_intensities_min_default as default, type };