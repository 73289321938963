import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/common/user/user.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notifications;
  loading = true;
  currentNotification;

  constructor(
    private userService: UserService, 
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    await this.loadNotifications();
  }

  async loadNotifications() {
    const owerDetai = await this.userService.getNotifications().toPromise();
    this.notifications = owerDetai.map((n) => ({
      id: n.id,
      text: `Diff drop on ${
        new Date(n.createdAt).toISOString().split('T')[0]
      } - [${n.alertPeriod}]`,
    }));
    this.loading = false;
  }

  navigate(id) {
    this.router.navigate([`/home/diff-alert/${id}`]);
  }
}