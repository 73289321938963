import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToasterComponent } from 'src/app/components/toaster/toaster.component';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  durationInSeconds = 5;

 constructor(private snackBar : MatSnackBar) { }
  /**
   * This is to show a toaster message any where
   * @param message 
   */
  createSnackBar(message : String){
     this.snackBar.openFromComponent(ToasterComponent,{
       data :{
         message: message
       },
      duration: this.durationInSeconds * 1000,
      verticalPosition :'bottom',
      horizontalPosition:'center'
    })
  }
}
