@if (notifications?.length) {
  <button
    class="notification-button mx-3"
    mat-mini-fab
    [matMenuTriggerFor]="bell"
    >
    <mat-icon
      [matBadge]="notifications.length"
      matBadgeColor="warn"
      class="notification-button__icon"
      >notifications_none</mat-icon
      >
    </button>
  }
  <mat-menu #bell="matMenu" xPosition="before" class="notification-menu">
    @if (notifications?.length) {
      <div>
        @for (n of notifications; track n) {
          <button
            (click)="navigate(n.id)"
            mat-menu-item
            class="notification-menu__button"
            >
            <mat-icon class="notification-menu__icon_pink"
              >notifications_none</mat-icon
              >{{ n.text }}
            </button>
          }
        </div>
      }
    </mat-menu>
    