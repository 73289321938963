<div class="layout-container">
  <app-header (isShowSidebar)="sidenav.toggle($event)" [isMenuOpened]="isShowSidebar" class="sticky-top"></app-header>
  <mat-sidenav-container class="layout" >
    <mat-sidenav #sidenav mode="side" [(opened)]="isShowSidebar" class="layout-sidebar" [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches">
      <app-sidebar></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-feedback-widget></app-feedback-widget>
  <app-footer class="footer"></app-footer>
</div>