<footer class="text-center z-3 shadow-lg">
  <!-- Copyright -->
  <div class="text-center p-2 d-flex justify-content-between align-items-center">
    <div class="d-flex justify-content-start align-items-center mx-4 col-6">
        <p class="m-0">© {{ currentYear }} Copyright  
          <a href="https://speetals.com/" target="_blank">Speetals.com </a>
        </p> 
    </div>
    <div class="d-flex justify-content-center align-items-center mx-2 col-2" style="height: 28px;">
      <a [routerLink]="'/buyPlan'" style="text-decoration: none">
        <div class="pricing d-flex justify-content-center align-items-center" style="height: 28px;">
          <mat-icon class="mat-icon">credit_card</mat-icon> 
          <mat-list-item routerLinkActive="active"[routerLinkActiveOptions]="{ exact: true }" class="p-0" style="height: 28px;">
            <span class="mx-2 pricing">Pricing</span>
          </mat-list-item>
        </div>
      </a>
      <div class="vr"></div>
      <a href="https://twitter.com/SpeetalsTool" target="_blank" rel="noopener noreferrer" class="X">
        <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 512 512">
          <style>svg{fill:#f5f5f5}</style>
          <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
        </svg>
      </a>
    </div>

  </div>
  <!-- Copyright -->
</footer>
